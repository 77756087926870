import axios from '../request'

// 注册接口
export const registerApi = (phone, pwd, name) => axios.post("/api/v1/pri/user/register", {
    "phone": phone,
    "pwd": pwd,
    "name": name
})

// 登录接口
export const loginApi = (phone, pwd) => axios.post("/api/v1/pri/user/login", {
    phone, pwd
})


// 轮播图接口
export const getBanner = () => axios.get("/api/v1/pub/video/list_banner")

// 视频列表
export const getVideoList = () => axios.get("/api/v1/pub/video/list")

// 视频详情
// export const getVideoDetail = (vid) => axios.post("/api/v1/pub/video/find_detail_by_id",{
//     vid
// })
export const getVideoDetail = (vid) => axios.get("/api/v1/pub/video/find_detail_by_id", {
    params: {
        video_id: vid
    }
})

// 下单接口
export const saveOrder = (token, vid) => axios.post("/api/v1/pri/order/save", {
    "video_id": vid
}, {
    headers: {
        "token": token
    }
})

// 订单列表
export const getOrderList = (token) => axios.get("/api/v1/pri/order/list", {
    params: {
        token
    }
})

// 用户信息
export const getUserInfo = (token) => axios.get("/api/v1/pri/user/find_by_token", {
    params: {
        "token": token
    }
})

// 根据车型和科目获取题库ids
export const getQuestionsByCarTypeAndSubject = (cartype, subject, type ,chapterid) => axios.post("/api/v1/pub/subject/find_by_cartype_subject", {
    cartype, subject, type, chapterid
})

// 根据id获取题目信息
export const getQuestionById = (id) => axios.get("/api/v1/pub/subject/find_by_id", {
    params: {
        'id': id
    }
})

// 根据车型和科目获取考试题库ids
export const getExamQuestionsByCarTypeAndSubject = (cartype, subject) => axios.post("/api/v1/pub/subject/find_exam_by_cartype_subject", {
    cartype, subject
})


// 根据车型和科目获取章节列表
export const getChaptersByCarTypeAndSubject = (cartype, subject) => axios.post("/api/v1/pub/subject/find_chapter_by_cartype_subject", {
    "cartype":cartype, "subject":subject
})

