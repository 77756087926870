<template>
  <div>
    <div class="top_d">
      <van-circle
        class="circle"
        v-model="currentRate"
        :rate="this.$route.query.get_points"
        :clockwise="false"
        :color="gradientColor"
        layer-color="#ebedf0"
        size="140px"
        :text="scoreText"
        :speed="50"
        :stroke-width="50"
      />
    </div>

    <div class="result_d">
      <span class="answer_s"
        >答对：<span class="right_s"
          >{{ this.$route.query.right_count }} 题</span
        ></span
      >
      <span class="answer_s"
        >答错：<span class="wrong_s"
          >{{ this.$route.query.wrong_count }} 题</span
        ></span
      >
      <span class="answer_s"
        >未答：<span class="undo_s"
          >{{
            this.$route.query.total_count -
            this.$route.query.wrong_count -
            this.$route.query.right_count
          }}
          题</span
        ></span
      >
    </div>
    <van-divider :style="{ borderColor: '#1989fa', margin: '40px 0' }" />

    <div class="summary_d" v-if="this.$route.query.get_points > 59">
      <img :src="examPass" />
      <div class="pass_d">恭喜您通过考试 ~</div>
    </div>
    <div class="summary_d" v-else>
      <img :src="examUnpass" />
      <div class="unpass_d">很遗憾您未通过考试 ~</div>
    </div>
  </div>
</template>

<script>
import examPass from "@/assets/ic_exam_pass.png";
import examUnpass from "@/assets/ic_exam_unpass.png";

import Vue from "vue";
import { Circle, Divider } from "vant";
Vue.use(Circle);
Vue.use(Divider);
export default {
  data() {
    return {
      examPass: examPass,
      examUnpass: examUnpass,
      currentRate: 0,
      gradientColor: {
        "0%": "#3fecff",
        "100%": "#6149f6",
      },
      scoreText: "",
    };
  },

  mounted() {
    this.scoreText = "成绩：" + this.$route.query.get_points+"分";
  },
};
</script>

<style lang="scss" scoped>
.top_d {
  text-align: center;
  margin-top: 40px;
}

.circle {
  font-size: 20px;
}

.result_d {
  display: flex; /*重要*/
  justify-content: space-between; /*重要*/
  text-align: center;
  margin-top: 50px;
}

.answer_s {
  width: 33%;
  text-align: center;
  font-size: 16px;
  color: #333333;
}

.right_s {
  color: #71cf0a;
  font-size: 17px;
}

.wrong_s {
  color: #da4949;
  font-size: 17px;
}

.undo_s {
  color: #1296db;
  font-size: 17px;
}

.summary_d {
  text-align: center;
  margin-top: 30px;
}

.summary_text {
  text-align: center;
}

.pass_d {
  color: #71cf0a;
  margin-top: 20px;
  font-size: 18px;
}

.unpass_d{
  color: #da4949;
  margin-top: 20px;
  font-size: 18px;
}
</style>