<template>
  <div class="cate_box">
    <div>
      <router-link
        class="content"
        v-for="(item, ind) in chapterList"
        :key="item.id"
        :to="{
          path: '/exercise',
          query: {
            subject: item.subject,
            car_type: $route.query.car_type,
            type:2,
            chapter_id: item.id,
          },
        }"
      >
        <h1>
          第{{ ind + 1 }}章&nbsp;{{ item.title }}
          <span class="title_num">&nbsp;({{ item.num }}题)</span>
        </h1>
        <van-divider
          :style="{
            borderColor: 'E9E9E9',
            height: '0.5px',
          }"
        ></van-divider>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getChaptersByCarTypeAndSubject } from "@/api/getData.js";
import Vue from "vue";
import { Divider } from "vant";
Vue.use(Divider);
export default {
  data() {
    return {
      chapterList: [],
    };
  },

  methods: {
    async getChapterListData(cartype, subject) {
      try {
        const result = await getChaptersByCarTypeAndSubject(cartype, subject);
        if (result.data.code == 0) {
          this.chapterList = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getChapterListData(
      this.$route.query.car_type,
      this.$route.query.subject
    );
  },
};
</script>

<style lang="scss" scoped>
// 目录包裹层设置边距
.cate_box {
  padding: 0 12px 50px;
  background-color: #fff;
  margin-top: 40px;
}

//每一章包裹层
.content {
  margin: 0 auto;
  // 章标题
  & h1 {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    color: #333333;
    font-weight: bolder;
    // 设置章标题过长,超过行宽度省略隐藏
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .title_num {
    color: #333333;
    font-size: 16px;
  }
}
</style>