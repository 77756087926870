import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Subject/Main.vue'
import Home from '../views/Home/Home.vue'
import CourseDetail from '../views/CourseDetail/CourseDetail.vue'
import Login from '../views/Login/Login.vue'
import Order from '../views/Order/Order.vue'
import Pay from '../views/Pay/Pay.vue'
import Personal from '../views/Personal/Personal.vue'
import Register from '../views/Register/Register.vue'
import Exercise from '../views/Subject/Exercise.vue'
import Exam from '../views/Subject/Exam.vue'
import ExamResult from '../views/Subject/ExamResult.vue'
import Chapter from '../views/Subject/Chapter.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: {
      title: '景然驾考'
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/exercise",
    name: "Exercise",
    component: Exercise,
    meta: {
      title: '题库练习'
    }
  }, {
    path: "/exam",
    name: "Exam",
    component: Exam,
    meta: {
      title: '模拟考试'
    }
  }, {
    path: "/examResult",
    name: "ExamResult",
    component: ExamResult,
    meta: {
      title: '考试成绩'
    }
  }, {
    path: "/chapter",
    name: "Chapter",
    component: Chapter,
    meta: {
      title: '章节练习'
    }
  }, {
    path: "/courseDetail",
    name: "CourseDetail",
    // component:CourseDetail
    // 按需加载
    component: () => import("../views/CourseDetail/CourseDetail.vue")
  }, {
    path: "/login",
    name: "Login",
    component: Login
  }, {
    path: "/order",
    name: "Order",
    component: Order,
    meta: { requiresAuth: true }
  }, {
    path: "/pay",
    name: "Pay",
    component: Pay,
    meta: { requiresAuth: true }
  }, {
    path: "/personal",
    name: "Personal",
    component: Personal,
    meta: { requiresAuth: true }
  }, {
    path: "/register",
    name: "Register",
    component: Register
  }

]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
