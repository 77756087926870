import axios from 'axios'

const service  = axios.create({
    // url = baseurl + request url
    // baseURL : "http://192.168.137.1:8089",
    baseURL : "https://www.jrjrit.cn",
    
    // 配置请求超时时间
    timeout: 5000
})


export default service