var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cate_box"},[_c('div',_vm._l((_vm.chapterList),function(item,ind){return _c('router-link',{key:item.id,staticClass:"content",attrs:{"to":{
        path: '/exercise',
        query: {
          subject: item.subject,
          car_type: _vm.$route.query.car_type,
          type:2,
          chapter_id: item.id,
        },
      }}},[_c('h1',[_vm._v(" 第"+_vm._s(ind + 1)+"章 "+_vm._s(item.title)+" "),_c('span',{staticClass:"title_num"},[_vm._v(" ("+_vm._s(item.num)+"题)")])]),_c('van-divider',{style:({
          borderColor: 'E9E9E9',
          height: '0.5px',
        })})],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }