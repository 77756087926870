<template>
  <div>
    <main-header></main-header>
    <div>
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item
          v-model="subject"
          :options="option1"
          @change="subjectChange"
        />
        <van-dropdown-item
          v-model="cartype"
          :options="option2"
          @change="cartypeChange"
        />
      </van-dropdown-menu>
    </div>

    <div class="col_d">
      <router-link
        class="col_s"
        :to="{ path: '/exam', query: { subject: subject, car_type: cartype } }"
      >
        <p>
          <img class="icon_i" :src="examMn" />
        </p>
        <p class="col_text_p">模拟考试</p>
        <!-- <p class="col_text_2_p">均分：60</p> -->
      </router-link>
      <router-link
        class="col_s"
        :to="{
          path: '/exercise',
          query: { subject: subject, car_type: cartype, type: 0 },
        }"
      >
        <p>
          <img class="icon_i" :src="exerciseSx" />
        </p>
        <p class="col_text_p">顺序练习</p>
        <!-- <p class="col_text_2_p">120/1110</p> -->
      </router-link>
    </div>
    <div class="col_d">
        <router-link
        class="col_s"
        :to="{
          path: '/chapter',
          query: { subject: subject, car_type: cartype },
        }"
      >
        <p>
          <img class="icon_i" :src="exerciseZj" />
        </p>
        <p class="col_text_p">章节练习</p>
      </router-link>
      <router-link
        class="col_s"
        :to="{
          path: '/exercise',
          query: { subject: subject, car_type: cartype, type: 1 },
        }"
      >
        <p>
          <img class="icon_i" :src="exerciseSj" />
        </p>
        <p class="col_text_p">随机练习</p>
      </router-link>
      <!-- <span class="col_empty_s"> </span>
      <span class="col_empty_s"> </span> -->
    </div>
    <!-- <div class="footer_d" align="center">
       <p class="footer-company-name">&copy; 2021.句容景然信息技术有限公司&nbsp;&nbsp;<a class="footer-company-name" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2021004519号-2</a></p>
    </div> -->
  </div>
</template>

<script>
import examMn from "@/assets/ic_exam_mn.png";
import exerciseSx from "@/assets/ic_exercise_sx.png";
import exerciseZj from "@/assets/ic_exercise_zj.png";
import exerciseSj from "@/assets/ic_exercise_sj.png";
import Vue from "vue";
import { DropdownMenu, DropdownItem, Grid, GridItem, Toast } from "vant";
import Header from "../CourseDetail/Components/Header.vue";
import MainHeader from "./Component/MainHeader.vue";
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Toast);
export default {
  components: { Header, MainHeader },
  data() {
    return {
      examMn: examMn,
      exerciseSx: exerciseSx,
      exerciseZj: exerciseZj,
      exerciseSj: exerciseSj,
      subject: 1,
      cartype: 0,
      option1: [
        { text: "科目一", value: 1 },
        // { text: "科目四", value: 4 },
      ],
      option2: [
        { text: "小车", value: 0 },
        { text: "货车", value: 1 },
        { text: "客车", value: 2 },
        { text: "摩托车", value: 3 },
      ],
      avergeScore:0,
      completedNum:0,
      totalNum:0,

    };
  },

  computed:{

  },

  methods: {
    // click1() {
    // function currentOS() {
    //   const ua = window.navigator.userAgent;
    //   console.info(ua);
    //   return {
    //     isAndroid: ua.indexOf("Android") > -1, // android终端
    //     isiOS: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    //     weiXin: ua.indexOf("MicroMessenger") > -1, // 微信浏览器
    //   };
    // }

    // let os = currentOS();
    // console.log(os, window.webkit);
    // if (os && os.isAndroid) {
    //   window.android.androidFinish();
    // }
    // },
    subjectChange(value) {
      localStorage.setItem(
        "last_subject",
        JSON.stringify({
          subject: value,
        })
      );
    },
    cartypeChange(value) {
      localStorage.setItem(
        "last_cartype",
        JSON.stringify({
          cartype: value,
        })
      );
    },
  },

  mounted() {
    const lastSubject = localStorage.getItem("last_subject");
    if (lastSubject) {
      this.subject = JSON.parse(lastSubject).subject;
    }

    const lastCartype = localStorage.getItem("last_cartype");
    if (lastCartype) {
      this.cartype = JSON.parse(lastCartype).cartype;
    }
  },

  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.col_d {
  margin-top: 30px;
  margin-left: 28px;
  margin-right: 28px;
  text-align: center;
  display: flex; /*重要*/
  justify-content: space-between; /*重要*/
  text-align: center;
}

.col_s {
  text-align: center;
  width: 44%;
  padding-top: 32px;
  padding-bottom: 32px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  background-color: #e9e9e9;
  margin: 5px;
}

.col_empty_s {
  text-align: center;
  width: 40%;
  padding-top: 26px;
  padding-bottom: 26px;
  margin: 5px;
}

.col_text_p {
  margin-top: 12px;
  font-size: 15px;
  color: #333333;
}

.col_text_2_p {
  margin-top: 12px;
  font-size: 15px;
  color: #1989fa;
}

.icon_i {
  width: 40px;
}

.footer_d {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #9EAAB4;
  font-size: 12px;

}
</style>