var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main-header'),_c('div',[_c('van-dropdown-menu',{attrs:{"active-color":"#1989fa"}},[_c('van-dropdown-item',{attrs:{"options":_vm.option1},on:{"change":_vm.subjectChange},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('van-dropdown-item',{attrs:{"options":_vm.option2},on:{"change":_vm.cartypeChange},model:{value:(_vm.cartype),callback:function ($$v) {_vm.cartype=$$v},expression:"cartype"}})],1)],1),_c('div',{staticClass:"col_d"},[_c('router-link',{staticClass:"col_s",attrs:{"to":{ path: '/exam', query: { subject: _vm.subject, car_type: _vm.cartype } }}},[_c('p',[_c('img',{staticClass:"icon_i",attrs:{"src":_vm.examMn}})]),_c('p',{staticClass:"col_text_p"},[_vm._v("模拟考试")])]),_c('router-link',{staticClass:"col_s",attrs:{"to":{
        path: '/exercise',
        query: { subject: _vm.subject, car_type: _vm.cartype, type: 0 },
      }}},[_c('p',[_c('img',{staticClass:"icon_i",attrs:{"src":_vm.exerciseSx}})]),_c('p',{staticClass:"col_text_p"},[_vm._v("顺序练习")])])],1),_c('div',{staticClass:"col_d"},[_c('router-link',{staticClass:"col_s",attrs:{"to":{
        path: '/chapter',
        query: { subject: _vm.subject, car_type: _vm.cartype },
      }}},[_c('p',[_c('img',{staticClass:"icon_i",attrs:{"src":_vm.exerciseZj}})]),_c('p',{staticClass:"col_text_p"},[_vm._v("章节练习")])]),_c('router-link',{staticClass:"col_s",attrs:{"to":{
        path: '/exercise',
        query: { subject: _vm.subject, car_type: _vm.cartype, type: 1 },
      }}},[_c('p',[_c('img',{staticClass:"icon_i",attrs:{"src":_vm.exerciseSj}})]),_c('p',{staticClass:"col_text_p"},[_vm._v("随机练习")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }