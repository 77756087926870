<template>
<div class="logo_d">
  <img class="logo_i" :src="logo" />
</div>

</template>

<script>
import logo from "@/assets/ic_logo.png";
export default {

  data() {
    return {
      logo:logo
     
    };
  },
};
</script>

<style lang="scss" scoped>

.logo_d{
  margin-left: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.logo_i{
  height: 40px;
}
</style>