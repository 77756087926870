<template>
  <div>
    <div class="cate_box">
      <div class="top_exercise">
        <div class="content">
          <h1>
            <van-tag
              color="#43CD80"
              type="primary"
              size="large"
              v-if="question.type == 0"
              >判断</van-tag
            >
            <van-tag color="#FF7F24" type="primary" size="large" v-else
              >单选</van-tag
            >
            {{ index + 1 }}/{{ questionList.length }}、{{ question.question }}
          </h1>
        </div>

        <div class="image_d" align="center" v-if="question.image">
          <img
            class="image_i"
            :src="getImgUrl(question.image)"
            @click="viewPic(question.image)"
          />
        </div>

        <div class="option_x">
          <van-checkbox v-model="checkedA" @change="changeA">
            {{ question.optiona }}
            <template #icon="">
              <img class="img-icon" v-if="stateA === 1" :src="rightIcon" />

              <img class="img-icon" v-else-if="stateA === 2" :src="wrongIcon" />

              <img class="img-icon" v-else :src="optiona" />
            </template>
          </van-checkbox>
        </div>

        <div class="option_x">
          <van-checkbox v-model="checkedB" @change="changeB">
            {{ question.optionb }}
            <template #icon="">
              <img class="img-icon" v-if="stateB === 1" :src="rightIcon" />

              <img class="img-icon" v-else-if="stateB === 2" :src="wrongIcon" />

              <img class="img-icon" v-else :src="optionb" />
            </template>
          </van-checkbox>
        </div>
        <div class="option_x" v-if="question.type === 1">
          <van-checkbox v-model="checkedC" @change="changeC">
            {{ question.optionc }}
            <template #icon="">
              <img class="img-icon" v-if="stateC === 1" :src="rightIcon" />

              <img class="img-icon" v-else-if="stateC === 2" :src="wrongIcon" />

              <img class="img-icon" v-else :src="optionc" />
            </template>
          </van-checkbox>
        </div>
        <div class="option_x" v-if="question.type === 1">
          <van-checkbox v-model="checkedD" @change="changeD">
            {{ question.optiond }}
            <template #icon="">
              <img class="img-icon" v-if="stateD === 1" :src="rightIcon" />

              <img class="img-icon" v-else-if="stateD === 2" :src="wrongIcon" />

              <img class="img-icon" v-else :src="optiond" />
            </template>
          </van-checkbox>
        </div>
      </div>

      <div class="button_d">
        <span class="button_bn"
          ><van-button
            color="linear-gradient(to right,#1577FF, #2FAFFF)"
            @click="preQuestion"
          >
            上 一 题
          </van-button></span
        >
        <span class="button_bn"
          ><van-button
            color="linear-gradient(to right,#1577FF, #2FAFFF)"
            @click="nextQuestion"
            >下 一 题</van-button
          ></span
        >
      </div>

      <div class="auto_right">
        <cube-checkbox v-model="autoNext" shape="square"
          >答对自动下一题</cube-checkbox
        >
      </div>
      <div class="e_info">
        正确：<span class="e_right">{{ rightCount }}题</span>&nbsp; |
        &nbsp;错误：<span class="e_wrong">{{ wrongCount }}题</span>
      </div>

      <div>
        <van-divider
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0 0px',
          }"
        ></van-divider>
      </div>
      <div class="btns_d">
        <van-button plain type="primary" @click="show = true" size="small"
          >显示题目索引</van-button
        >
        <van-button
          class="vb_answer"
          plain
          type="info"
          size="small"
          @click="showAnalysis"
          >{{ analysis ? "隐藏答案解析" : "显示答案解析" }}</van-button
        >
      </div>

      <div>
        <van-action-sheet v-model="show" title="答题卡">
          <div class="scroll-list-wrap" v-if="show">
            <van-row
              v-for="indexrow in rowNum"
              :key="indexrow"
              align="center"
              type="flex"
              justify="space-around"
            >
              <van-col
                span="2"
                v-for="indexcol in colNum"
                :key="indexcol"
                :class="[
                  {
                    'rowcol-right':
                      statusList[(indexrow - 1) * colNum + indexcol - 1] === 1,
                  },
                  {
                    'rowcol-wrong':
                      statusList[(indexrow - 1) * colNum + indexcol - 1] === 2,
                  },
                  {
                    rowcol:
                      statusList[(indexrow - 1) * colNum + indexcol - 1] ===
                        0 && index !== (indexrow - 1) * colNum + indexcol - 1,
                  },
                  {
                    'rowcol-select':
                      statusList[(indexrow - 1) * colNum + indexcol - 1] ===
                        0 && index === (indexrow - 1) * colNum + indexcol - 1,
                  },
                ]"
                @click="selectQuestion((indexrow - 1) * colNum + indexcol - 1)"
                >{{
                  (indexrow - 1) * colNum + indexcol > questionList.length
                    ? ""
                    : (indexrow - 1) * colNum + indexcol
                }}</van-col
              >
            </van-row>
          </div>
        </van-action-sheet>
      </div>
      <div class="analysis" v-if="analysis || analysisTemp">
        <h1>答案解析</h1>

        <div class="analysis_bg">
          <p class="p_answer">答案：{{ question.answer }}</p>
          <p class="p_analysis">{{ removeBr(question.analysis) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionsByCarTypeAndSubject } from "@/api/getData.js";
import { getQuestionById } from "@/api/getData.js";
import Vue from "vue";
import {
  Checkbox,
  CheckboxGroup,
  Button,
  Tag,
  Divider,
  Col,
  Row,
  ActionSheet,
  Dialog,
  ImagePreview,
} from "vant";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Col);
Vue.use(Row);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(ImagePreview);

import optiona from "@/assets/ic_option_a.png";
import optionb from "@/assets/ic_option_b.png";
import optionc from "@/assets/ic_option_c.png";
import optiond from "@/assets/ic_option_d.png";
import optionRight from "@/assets/ic_option_right.png";
import optionWrong from "@/assets/ic_option_wrong.png";
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      optiona: optiona,
      optionb: optionb,
      optionc: optionc,
      optiond: optiond,
      rightIcon: optionRight,
      wrongIcon: optionWrong,
      questionList: [],
      question: {},
      index: 0,
      checkedA: false,
      checkedB: false,
      checkedC: false,
      checkedD: false,
      stateA: 0,
      stateB: 0,
      stateC: 0,
      stateD: 0,
      statusList: [],
      answerList: [],
      rowNum: 0,
      colNum: 8,
      show: false,
      analysisTemp: false,
      analysis: false,
      autoNext: true,
      rightCount: 0,
      wrongCount: 0,
    };
  },

  methods: {
    async getQuestionListData(cartype, subject, type, chapterid) {
      console.log("getQuestionListData");

      try {
        const result = await getQuestionsByCarTypeAndSubject(
          cartype,
          subject,
          type,
          chapterid
        );
        if (result.data.code == 0) {
          this.questionList = result.data.data || [];
          this.rowNum = Math.ceil(this.questionList.length / this.colNum) + 1;
          this.questionList.forEach((item, index) => {
            Vue.set(this.statusList, index, 0);
            if (this.$route.query.q_id == null) {
              this.index = 0;
            } else {
              if (item == parseInt(this.$route.query.q_id)) {
                this.index = index;
              }
            }
          });

          this.getQuestionById(this.questionList[this.index]);
        }
      } catch (error) {}
    },

    async getQuestionById(id) {
      try {
        const result = await getQuestionById(id);
        if (result.data.code == 0) {
          this.question = result.data.data;
        }
      } catch (error) {}
    },

    removeBr(str) {
      return str
        .replace(new RegExp("<br>", "gm"), "")
        .replace(new RegExp("<br/>", "gm"), "");
    },

    showAnalysis() {
      this.analysis = !this.analysis;
      if (!this.analysis) {
        this.analysisTemp = false;
      }
    },

    getImgUrl(image) {
      return (
        "https://pic.jrjrit.cn/subject/one/" +
        image +
        ".jpg?x-oss-process=style/watermark"
      );
    },

    viewPic(image) {
      ImagePreview({
        images: [this.getImgUrl(image)],
        closeable: true,
      });
    },

    selectQuestion(position) {
      if (position + 1 > this.questionList.length) {
        return;
      }
      this.index = position;
      this.getQuestionById(this.questionList[this.index]);
      this.stateA = 0;
      this.stateB = 0;
      this.stateC = 0;
      this.stateD = 0;
      this.checkedA = false;
      this.checkedB = false;
      this.checkedC = false;
      this.checkedD = false;
      this.show = false;
      // this.$store.dispatch("setLastIndex", this.index);
      if (this.$route.query.type == 0 || this.$route.query.type == 2) {
        localStorage.setItem(
          "lastIndex_" +
            this.$route.query.car_type +
            "_" +
            this.$route.query.subject +
            "_" +
            this.$route.query.chapter_id,
          JSON.stringify({
            i: this.index,
          })
        );
      }
      if (this.answerList[this.index]) {
        this.analysisTemp = true;
        if (this.question.answer == "A") {
          this.stateA = 1;
          this.checkedA = true;
        } else if (this.question.answer == "B") {
          this.stateB = 1;
          this.checkedB = true;
        } else if (this.question.answer == "C") {
          this.stateC = 1;
          this.checkedC = true;
        } else if (this.question.answer == "D") {
          this.stateD = 1;
          this.checkedD = true;
        }

        if (this.answerList[this.index] == "A" && this.stateA != 1) {
          this.stateA = 2;
          this.checkedA = true;
        } else if (this.answerList[this.index] == "B" && this.stateB != 1) {
          this.stateB = 2;
          this.checkedB = true;
        } else if (this.answerList[this.index] == "C" && this.stateC != 1) {
          this.stateC = 2;
          this.checkedC = true;
        } else if (this.answerList[this.index] == "D" && this.stateD != 1) {
          this.stateD = 2;
          this.checkedD = true;
        }
      } else {
        this.analysisTemp = false;
      }
    },

    preQuestion() {
      if (this.index == 0) {
        return;
      }
      this.index = this.index - 1;
      this.getQuestionById(this.questionList[this.index]);
      this.stateA = 0;
      this.stateB = 0;
      this.stateC = 0;
      this.stateD = 0;
      this.checkedA = false;
      this.checkedB = false;
      this.checkedC = false;
      this.checkedD = false;
      // this.$store.dispatch("setLastIndex", this.index);
      if (this.$route.query.type == 0 || this.$route.query.type == 2) {
        localStorage.setItem(
          "lastIndex_" +
            this.$route.query.car_type +
            "_" +
            this.$route.query.subject +
            "_" +
            this.$route.query.chapter_id,
          JSON.stringify({
            i: this.index,
          })
        );
      }
      if (this.answerList[this.index]) {
        this.analysisTemp = true;
        if (this.question.answer == "A") {
          this.stateA = 1;
          this.checkedA = true;
        } else if (this.question.answer == "B") {
          this.stateB = 1;
          this.checkedB = true;
        } else if (this.question.answer == "C") {
          this.stateC = 1;
          this.checkedC = true;
        } else if (this.question.answer == "D") {
          this.stateD = 1;
          this.checkedD = true;
        }

        if (this.answerList[this.index] == "A" && this.stateA != 1) {
          this.stateA = 2;
          this.checkedA = true;
        } else if (this.answerList[this.index] == "B" && this.stateB != 1) {
          this.stateB = 2;
          this.checkedB = true;
        } else if (this.answerList[this.index] == "C" && this.stateC != 1) {
          this.stateC = 2;
          this.checkedC = true;
        } else if (this.answerList[this.index] == "D" && this.stateD != 1) {
          this.stateD = 2;
          this.checkedD = true;
        }
      } else {
        this.analysisTemp = false;
      }
    },
    nextQuestion() {
      if (this.index + 1 == this.questionList.length) {
        return;
      }
      this.index = this.index + 1;
      this.getQuestionById(this.questionList[this.index]);
      this.stateA = 0;
      this.stateB = 0;
      this.stateC = 0;
      this.stateD = 0;
      this.checkedA = false;
      this.checkedB = false;
      this.checkedC = false;
      this.checkedD = false;
      // this.$store.dispatch("setLastIndex", this.index);
      if (this.$route.query.type == 0 || this.$route.query.type == 2) {
        localStorage.setItem(
          "lastIndex_" +
            this.$route.query.car_type +
            "_" +
            this.$route.query.subject +
            "_" +
            this.$route.query.chapter_id,
          JSON.stringify({
            i: this.index,
          })
        );
      }

      if (this.answerList[this.index]) {
        this.analysisTemp = true;
        if (this.question.answer == "A") {
          this.stateA = 1;
          this.checkedA = true;
        } else if (this.question.answer == "B") {
          this.stateB = 1;
          this.checkedB = true;
        } else if (this.question.answer == "C") {
          this.stateC = 1;
          this.checkedC = true;
        } else if (this.question.answer == "D") {
          this.stateD = 1;
          this.checkedD = true;
        }

        if (this.answerList[this.index] == "A" && this.stateA != 1) {
          this.stateA = 2;
          this.checkedA = true;
        } else if (this.answerList[this.index] == "B" && this.stateB != 1) {
          this.stateB = 2;
          this.checkedB = true;
        } else if (this.answerList[this.index] == "C" && this.stateC != 1) {
          this.stateC = 2;
          this.checkedC = true;
        } else if (this.answerList[this.index] == "D" && this.stateD != 1) {
          this.stateD = 2;
          this.checkedD = true;
        }
      } else {
        this.analysisTemp = false;
      }
    },

    changeA(checked) {
      if (
        this.stateA != 0 ||
        this.stateB != 0 ||
        this.stateC != 0 ||
        this.stateD != 0
      ) {
        return;
      }

      if (checked && this.question.answer == "A") {
        this.stateA = 1;
        this.$set(this.statusList, this.index, 1);
        this.$set(this.answerList, this.index, "A");
        this.analysisTemp = true;
        this.rightCount++;
        if (this.autoNext) {
          let _this = this;
          clearTimeout(_this);
          setTimeout(function () {
            _this.nextQuestion();
          }, 500);
        }
      } else if (checked && this.question.answer != "A") {
        this.stateA = 2;
        if (this.question.answer == "B") {
          this.stateB = 1;
        } else if (this.question.answer == "C") {
          this.stateC = 1;
        } else {
          this.stateD = 1;
        }
        this.$set(this.statusList, this.index, 2);
        this.$set(this.answerList, this.index, "A");
        this.analysisTemp = true;
        this.wrongCount++;
      } else {
        // this.stateA = 0;
      }
    },
    changeB(checked) {
      if (
        this.stateA != 0 ||
        this.stateB != 0 ||
        this.stateC != 0 ||
        this.stateD != 0
      ) {
        return;
      }

      if (checked && this.question.answer == "B") {
        this.stateB = 1;
        this.$set(this.statusList, this.index, 1);
        this.$set(this.answerList, this.index, "B");
        this.analysisTemp = true;
        if (this.autoNext) {
          let _this = this;
          clearTimeout(_this);
          setTimeout(function () {
            _this.nextQuestion();
          }, 500);
        }
        this.rightCount++;
      } else if (checked && this.question.answer != "B") {
        this.stateB = 2;
        if (this.question.answer == "A") {
          this.stateA = 1;
        } else if (this.question.answer == "C") {
          this.stateC = 1;
        } else {
          this.stateD = 1;
        }
        this.$set(this.statusList, this.index, 2);
        this.$set(this.answerList, this.index, "B");
        this.analysisTemp = true;
        this.wrongCount++;
      } else {
        // this.stateB = 0;
      }
    },
    changeC(checked) {
      if (
        this.stateA != 0 ||
        this.stateB != 0 ||
        this.stateC != 0 ||
        this.stateD != 0
      ) {
        return;
      }

      if (checked && this.question.answer == "C") {
        this.stateC = 1;
        this.$set(this.statusList, this.index, 1);
        this.$set(this.answerList, this.index, "C");
        this.analysisTemp = true;
        if (this.autoNext) {
          let _this = this;
          clearTimeout(_this);
          setTimeout(function () {
            _this.nextQuestion();
          }, 500);
        }
        this.rightCount++;
      } else if (checked && this.question.answer != "C") {
        this.stateC = 2;
        if (this.question.answer == "A") {
          this.stateA = 1;
        } else if (this.question.answer == "B") {
          this.stateB = 1;
        } else {
          this.stateD = 1;
        }
        this.$set(this.statusList, this.index, 2);
        this.$set(this.answerList, this.index, "C");
        this.analysisTemp = true;
        this.wrongCount++;
      } else {
        // this.stateC = 0;
      }
    },
    changeD(checked) {
      if (
        this.stateA != 0 ||
        this.stateB != 0 ||
        this.stateC != 0 ||
        this.stateD != 0
      ) {
        return;
      }

      if (checked && this.question.answer == "D") {
        this.stateD = 1;
        this.$set(this.statusList, this.index, 1);
        this.$set(this.answerList, this.index, "D");
        this.analysisTemp = true;
        if (this.autoNext) {
          let _this = this;
          clearTimeout(_this);
          setTimeout(function () {
            _this.nextQuestion();
          }, 500);
        }
        this.rightCount++;
      } else if (checked && this.question.answer != "D") {
        this.stateD = 2;
        if (this.question.answer == "A") {
          this.stateA = 1;
        } else if (this.question.answer == "B") {
          this.stateB = 1;
        } else {
          this.stateC = 1;
        }
        this.$set(this.statusList, this.index, 2);
        this.$set(this.answerList, this.index, "D");
        this.analysisTemp = true;
        this.wrongCount++;
      } else {
        // this.stateD = 0;
      }
    },
  },

  mounted() {
    this.getQuestionListData(
      this.$route.query.car_type,
      this.$route.query.subject,
      this.$route.query.type,
      this.$route.query.chapter_id
    );

    if (this.$route.query.type == 0 || this.$route.query.type == 2) {
      if (this.$route.query.q_id == null) {
        const ind = JSON.parse(
          localStorage.getItem(
            "lastIndex_" +
              this.$route.query.car_type +
              "_" +
              this.$route.query.subject +
              "_" +
              this.$route.query.chapter_id
          )
        );
        if (ind.i < 0 || ind.i > this.questionList.length - 1) {
          this.index = 0;
        }
        if (ind.i > 0) {
          Dialog.confirm({
            title: "温馨提醒",
            message: "上次练习到第" + (ind.i + 1) + "题，是否继续？",
          })
            .then(() => {
              // on confirm
              this.index = ind.i;
              this.selectQuestion(this.index);
            })
            .catch(() => {
              // on cancel
              this.index = 0;
              localStorage.removeItem("lastIndex");
            });
        }
      }
    }

    // console.log("mounted:" + ind.i);
  },
  beforeDestroy() {
    let _this = this;
    clearTimeout(_this);
  },
};
</script>

<style lang="scss" scoped>
// 目录包裹层设置边距
.cate_box {
  padding: 0 15px 0px;
  background-color: #fff;
  margin: 15px 0;
}

.top_exercise {
  min-height: 300px;
}

//每一章包裹层
.content {
  padding: 10px;
  // 章标题
  & h1 {
    font-size: 18px;
    width: 100%;
    line-height: 150%;
    // font-weight: bolder;
    // 设置章标题过长,超过行宽度省略隐藏
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    color: #000;
  }
}
.img-icon {
  height: 20px;
}

.option_x {
  padding: 10px;
}
.button_d {
  margin-top: 50px;
}
.button_bn {
  padding: 10px;
}
.auto_right {
  margin-top: 30px;
  margin-left: 0px;
  color: #666666;
  font-size: 14px;
}

.e_info {
  text-align: right;
  margin-top: 0px;
  margin-left: 10px;
  color: #888888;
  margin-right: 20px;
  font-size: 14px;
}

.e_right {
  color: #71cf0a;
}

.e_wrong {
  color: #da4949;
}

.rowcol {
  text-align: center;
  background-color: #e9e9e9;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 6px;
  font-size: 12px;
  border-radius: 4px;
}
.rowcol-select {
  text-align: center;
  background-color: #1296db;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 6px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
}
.rowcol-right {
  text-align: center;
  background-color: #71cf0a;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 6px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
}
.rowcol-wrong {
  text-align: center;
  background-color: #da4949;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 6px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
}
.scroll-list-wrap {
  height: 300px;
  overflow-y: scroll;
}
.vb_answer {
  margin-left: 15px;
  margin-right: 15px;
}
.btns_d {
  width: 100%;
  text-align: right;
}
.analysis {
  width: 100%;
  margin-top: 20px;
  // 章标题
  & h1 {
    font-size: 17px;
    width: 100%;
    margin-left: 6px;
    // font-weight: bolder;
    // 设置章标题过长,超过行宽度省略隐藏
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    color: #000;
  }
}

.analysis_bg {
  background-color: #e9e9e9;
  border-radius: 4px;
  padding: 12px 12px 12px 12px;
  line-height: 130%;
  margin-top: 12px;

  .p_answer {
    font-size: 15px;
    color: #000;
  }
  .p_analysis {
    margin-top: 6px;
    font-size: 15px;
    color: #333333;
  }
}
.image_d {
  align-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.image_i {
  max-width: 500px;
  width: 80%;
}
</style>